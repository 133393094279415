import { StyleFunctionProps, extendTheme } from "@chakra-ui/react";

// example theme
const theme = extendTheme({
  initialColorMode: "system",
  useSystemColorMode: true,
  colors: {
    brandColors: {
      50: "#e2f6fe",
      100: "#c5dfea",
      200: "#a5c9d9",
      300: "#84b3c8",
      400: "#639db7",
      500: "#4a849d",
      600: "#37667b",
      700: "#254959",
      800: "#122d38",
      900: "#001118",
    },
    brandColor: {
      100: "#2b5471",
    },
  },
  fonts: {
    body: "Gothic A1, Sans-serif",
    heading: "Gothic A1, Sans-serif",
    mono: "Gothic A1, Sans-serif",
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      "html, body": {
        fontSize: "sm",
        color: props.colorMode === "dark" ? "white" : "gray.600",
        lineHeight: "tall",
      },
    }),
  },
});

export default theme;
