import {
  Grid,
  Box,
  Heading,
  Button,
  Text,
  Divider,
  useColorMode,
  AspectRatio,
  Card,
  CardBody,
  CardHeader,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";

import { useMyVideo } from "video/video";

import { ContextProps, SocketContext } from "./Context";
import AutoScrollingBox from "./AutoScrollingBox";

const MyVideo: React.FC = () => {
  const { colorMode } = useColorMode();
  const context = useContext<ContextProps | null>(SocketContext);

  /* TODO: use proper type for this. */
  const { myFirstName, isCalling, myVideo, myStream, myTranscript }: any =
    context;
  useMyVideo(myVideo, myStream);

  // const [aiLastWord] = useState<string>(aiTranscript[-1]);

  /* Future icon order when call is in session */
  // Speaker * Camera * Mute * Share * End
  const boxMargin = [1, 2, 6, 10];
  const nameSize = { base: "sm", lg: "xl" };
  const fontSizeTranscript = [16, 20, 24, 32];
  const isSmallScreen = useBreakpointValue({ base: true }, { ssr: false });

  return (
    <Box margin={boxMargin}>
      <Grid>
        <Card variant="outline">
          <CardHeader pb="0px">
            <Heading color="brandColors.500" size={nameSize}>
              {myFirstName || "You"}
            </Heading>
          </CardHeader>
          <CardBody>
            <AspectRatio ratio={3 / 2}>
              <Box
                borderTopLeftRadius="lg"
                borderTopRightRadius="lg"
                overflow="hidden"
                boxShadow="md"
              >
                <video
                  muted
                  ref={myVideo}
                  autoPlay
                  playsInline
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "block", // Ensure the video is block level to fill container
                  }}
                />
              </Box>
            </AspectRatio>

            <Button
              // colorScheme={isCalling ? "red" : "whatsapp"}
              variant="solid"
              justifyContent="center"
              borderRadius="unset"
              borderBottomLeftRadius="10px"
              borderBottomRightRadius="10px"
              isDisabled={!isCalling}
              width="100%"
            ></Button>
          </CardBody>
        </Card>
        <Divider
          pt="10px"
          color={colorMode === "light" ? "gray.600" : "brandColors.800"}
        />
        {/* <WindupChildren> */}

        <AutoScrollingBox>
          {!isSmallScreen && (
            <Text
              color={colorMode === "light" ? "gray.600" : "brandColors.200"}
              fontSize={fontSizeTranscript}
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
              {myTranscript}
            </Text>
          )}
        </AutoScrollingBox>
        {/* </WindupChildren> */}
      </Grid>
    </Box>
  );
};

export default MyVideo;
