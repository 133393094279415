// import assistants from "./assistants";
import audio from "./audio";
// import avatars from "./avatars";
import demoScripts from "./demoScripts";
// import transcripts from "./transcripts";

const api = {
  // assistants: assistants,
  audio: audio,
  // avatars: avatars,
  demoScripts: demoScripts,
  // transcripts: transcripts,
};

export default api;
