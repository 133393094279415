import React, { useState, useRef } from "react";
import { Box, Button, Center, Heading, Image, Text } from "@chakra-ui/react";
import { BiCheck, BiCopy } from "react-icons/bi";

async function copyImg(src: string) {
  const img = await fetch(src);
  const imgBlob = await img.blob();
  try {
    navigator.clipboard.write([
      new ClipboardItem({
        "image/png": imgBlob, // change image type accordingly
      }),
    ]);
  } catch (error) {
    console.error(error);
  }
}

type CopyImageButtonProps = {
  src: string;
  style?: any;
};

const CopyImageButton: React.FC<CopyImageButtonProps> = ({ src, style }) => {
  const [isImageCopied, setIsImageCopied] = useState<boolean>(false);
  return (
    <Button
      leftIcon={isImageCopied ? <BiCheck /> : <BiCopy />}
      colorScheme="teal"
      variant={isImageCopied ? "solid" : "outline"}
      onClick={() => {
        copyImg(src);
        setIsImageCopied(true);
      }}
      style={style}
    >
      {isImageCopied ? "Image Copied!" : "Copy Image"}
    </Button>
  );
};

export default CopyImageButton;
