import { useEffect } from "react";

export const useMyVideo = (
  myVideo: React.MutableRefObject<HTMLVideoElement | null>,
  myStream: MediaStream | null,
) => {
  useEffect(() => {
    // show the webcam/microphone info in the video for the current user
    if (myVideo.current && myStream) {
      myVideo.current.srcObject = myStream;
    }
  }, [myVideo, myStream]);
};
