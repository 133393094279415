import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Grid,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

import logos, { ImageLookup } from "assets/logos";

const BRAND_OPTIONS_KEY = process.env.REACT_APP_BRAND_OPTIONS_KEY || "";
const logoImage = logos[BRAND_OPTIONS_KEY as keyof ImageLookup];
const decisionTreeAILogo = logos["DTAI" as keyof ImageLookup];

const companyName = process.env.REACT_APP_COMPANY_NAME || "Decision Tree AI";

const VisionPage: React.FC = () => {
  const gridMarginTop = { base: 0.5, md: 2, lg: 4 };
  return (
    <Box
      maxHeight="100vh"
      display="block"
      alignContent="center"
      justifyContent="center"
      padding="50px"
    >
      <Card overflow="auto">
        <CardBody>
          <Grid
            mt={gridMarginTop}
            justifyContent="center"
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          >
            <Image
              src={logoImage}
              alt={companyName}
              objectFit="cover"
              maxWidth="150"
            />

            <Image
              src={decisionTreeAILogo}
              alt="Decision Tree AI Logo"
              objectFit="cover"
            />
          </Grid>
          <Stack mt="6" spacing="3">
            <Heading color="brandColors.500" size="sm">
              Tier 1
            </Heading>
            <Text>
              Aspirational entreprenuers will be conected with the right people
              at the right time
            </Text>
            <Text color="blue.600" fontSize="2xl">
              $450
            </Text>
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter>
          <ButtonGroup spacing="2">
            <Button variant="solid" colorScheme="blue">
              Buy now
            </Button>
            <Button variant="ghost" colorScheme="blue">
              Add to cart
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default VisionPage;
