import asuLogo from "./asu_logo.webp";
import eldepal from "./eldepal.png";
import DTAILogotypeColor from "./DTAI-Logotype-Color.png";
import CroppedDTAILogo from "./cropped-DTAI-Logo.png";
import ellaLogo from "./ellaLogo2.png";
import sisterLoveLogo from "./sisterlove_logo.svg";
import solomonRelihanLogo from "./Solomon_Relihan_logo.jpg";
import OZOLogo from "./ozologo.png";
import venturesConcierge from "./venturesConcierge.svg";

export interface ImageLookup {
  [key: string]: string;
}

const images: ImageLookup = {
  asu: asuLogo,
  breakthrough: asuLogo,
  eldepal: eldepal,
  DTAI: CroppedDTAILogo,
  taproot: ellaLogo,
  OZO: OZOLogo,
  sisterLove: sisterLoveLogo,
  solomonRelihan: solomonRelihanLogo,
  venturesConcierge: venturesConcierge,
  MASTER: DTAILogotypeColor,
};
export default images;
