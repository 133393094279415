import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";

const CalendlyWidget: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box
      minH="100vh"
      className="calendly-inline-widget"
      data-url="https://calendly.com/decision-tree-ai/ai-consult?hide_gdpr_banner=1"
      style={{ minWidth: 320, height: "700px" }}
    ></Box>
  );
};

export default CalendlyWidget;
