import React from "react";
import {
  Box,
  Center,
  Heading,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import CopyImageButton from "components/buttons/CopyImageButton";

import qrcodes, { ImageLookup } from "../../assets/qr-codes";

const BRAND_OPTIONS_KEY = process.env.REACT_APP_BRAND_OPTIONS_KEY;
const qrCodeImage = qrcodes[BRAND_OPTIONS_KEY as keyof ImageLookup];

const QRCodePage: React.FC = () => {
  // Responsive image size
  const imageSize = useBreakpointValue({ base: "80%", sm: "90%", md: "100%" });

  // Responsive heading size
  const headingSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });

  return (
    <Card variant="outline" align="center">
      <CardHeader>
        <Heading
          color="brandColors.500"
          paddingTop="10px"
          size={headingSize} // Use responsive heading size
          textAlign="center"
        >
          Try this demo on mobile
        </Heading>
      </CardHeader>
      <CardBody>
        <Center>
          <Image
            src={qrCodeImage}
            alt="QR Code"
            objectFit="cover"
            width={imageSize} // Use responsive image size
            height={imageSize} // Use responsive image size
            borderRadius={6}
          />
        </Center>
      </CardBody>

      <CardFooter
        justify="space-between"
        flexWrap="wrap"
        sx={{
          "& > button": {
            minW: "136px",
          },
          // Responsive styles for the footer can be added here if needed
        }}
      >
        <Box flex="1">
          <CopyImageButton src={qrCodeImage} />
        </Box>
      </CardFooter>
    </Card>
  );
};

export default QRCodePage;
