import { Box, Heading, Center, Image, CardFooter } from "@chakra-ui/react";

import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import React from "react";

import CopyImageButton from "components/buttons/CopyImageButton";

const BRAND_OPTIONS_KEY = process.env.REACT_APP_BRAND_OPTIONS_KEY || "";
const PUBLIC_ROOT = process.env.REACT_APP_PUBLIC_ROOT;
const DEMO_TITLE = process.env.REACT_APP_DEMO_TITLE;

const PUBLIC_URL = PUBLIC_ROOT
  ? `https://${process.env.REACT_APP_PUBLIC_ROOT}`
  : "http://localhost:3000";

const imageUrl = `${PUBLIC_URL}/share-${BRAND_OPTIONS_KEY}-decision-tree-ai.png`;

const ShareImage: React.FC = () => {
  return (
    <Box
      maxHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Card variant="outline" align="center">
        <CardHeader>
          <Heading
            color="brandColors.500"
            padding="10px"
            size="md"
            textAlign="center"
          >
            Share this image content to social media
          </Heading>
        </CardHeader>
        <CardBody>
          <Center>
            <Box boxSize="lg">
              <Image
                src={imageUrl}
                alt={`Share ${DEMO_TITLE}`}
                mb={4}
                display="block"
                align="center"
                justifyContent="center"
                borderRadius={6}
              />
            </Box>
          </Center>
        </CardBody>
        <CardFooter>
          <CopyImageButton src={imageUrl} />
        </CardFooter>
      </Card>
    </Box>
  );
};

export default ShareImage;
