import React from "react";
import { Center, Heading, useBreakpointValue } from "@chakra-ui/react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ShareLinkButton from "components/buttons/ShareButton";

const PUBLIC_ROOT = process.env.REACT_APP_PUBLIC_ROOT;
const PUBLIC_URL = PUBLIC_ROOT
  ? `https://${process.env.REACT_APP_PUBLIC_ROOT}`
  : "http://localhost:3000";

const ShareLink: React.FC = () => {
  const headingSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });

  return (
    <Card variant="outline" align="center">
      <CardHeader>
        <Heading
          color="brandColors.500"
          paddingTop="10px"
          size={headingSize}
          textAlign="center"
        >
          Share <Link to={PUBLIC_URL}>{PUBLIC_ROOT}</Link>
        </Heading>
      </CardHeader>
      <CardBody>
        <Center>
          <ShareLinkButton
            textToCopy={PUBLIC_URL}
            resourceName="Link"
            size="lg"
          />
        </Center>
      </CardBody>

      {/* <CardFooter
          justify="space-between"
          flexWrap="wrap"
          sx={{
            "& > button": {
              minW: "136px",
            },
            // Responsive styles for the footer can be added here if needed
          }}
        >
          <Box flex="1">
            <CopyImageButton src={qrCodeImage} />
          </Box>
        </CardFooter> */}
    </Card>
  );
};

export default ShareLink;
