import breakthroughElena from "./breakthroughElena.png";
import breakthroughNia from "./breakthroughNia.png";
import EldepalTerri from "./EldepalTerri.jpeg";
import EllaTaproot from "./EllaTaproot.jpg";
import OZOLaura from "./ozolaura.png";
import venturesConciergeSydnie from "./VenturesConciergeSydnie.png";

export interface ImageLookup {
  [key: string]: string;
}

const images: ImageLookup = {
  asu: breakthroughElena,
  sisterLove: breakthroughNia,
  solomonRelihan: EllaTaproot, // TODO: replace with the new AI
  eldepal: EldepalTerri,
  taproot: EllaTaproot,
  OZO: OZOLaura,
  venturesConcierge: venturesConciergeSydnie,
};
export default images;
