import React, {
  createContext,
  useState,
  Dispatch,
  ReactElement,
  SetStateAction,
  MutableRefObject,
  useRef,
  useEffect,
} from "react";

import api from "api";

import { AssistantOption } from "api/assistantOptions/types";

import { defaultAssistantOption } from "api/assistantOptions";
import { defaultDemoScriptOption } from "api/demoScripts";
import {
  Script,
  ScriptOption,
  ScriptVariables,
  Scripts,
} from "api/demoScripts/types";

const config = {
  firstName: process.env.REACT_APP_MY_FIRST_NAME || "",
  lastName: process.env.REACT_APP_MY_LAST_NAME || "",
  defaultAssistant: defaultAssistantOption,
  defaultDemoScript: defaultDemoScriptOption,
};

interface Props {
  children: React.ReactElement;
}

export interface AIContextProps {
  /* TODO - names here or in another context?? */
  myFirstName: string;
  setMyFirstName: Dispatch<SetStateAction<string>>;
  aiName: string;
  setAiName: Dispatch<SetStateAction<string>>;
  aiMode: boolean;
  setAIMode: Dispatch<SetStateAction<boolean>>;
  myLastName: string;
  setMyLastName: Dispatch<SetStateAction<string>>;
  aiVoiceAudio: MutableRefObject<HTMLAudioElement | null>;
  selectedAI: AssistantOption;
  defaultDemoScript: ScriptOption | null;
  handleAISelectChange: (ai: AssistantOption) => void;
  handleScenarioSelectChange: (scenario: ScriptOption) => void;
  selectedScript: ScriptOption | null;
  // sendTranscriptToAI: (transcript: string) => void;
  clearAISelections: () => void;
}

const AIContext = createContext<AIContextProps | null>(null);

const AIContextProvider: React.FC<Props> = ({ children }): ReactElement => {
  /* AI */
  const [aiMode, setAIMode] = useState<boolean>(true);

  /* Names State*/
  const [myFirstName, setMyFirstName] = useState<string>(config.firstName);
  const [myLastName, setMyLastName] = useState<string>(config.lastName);
  const [aiName, setAIName] = useState<string>(config.defaultAssistant.aiName);

  /* Demo Script */
  const scriptToFollow = useRef<Script | []>([]);
  const scriptIndex = useRef<number>(0);

  /* AI Assistant */
  const [selectedAI, setSelectedAI] = useState<AssistantOption>(
    config.defaultAssistant,
  );

  const aiVoiceAudio = useRef<HTMLAudioElement | null>(null);

  /* Demo Scripts */
  const [selectedScript, setSelectedScript] = useState<ScriptOption | null>(
    config.defaultDemoScript,
  );
  const [defaultDemoScript] = useState<ScriptOption | null>(
    config.defaultDemoScript,
  );

  useEffect(() => {
    getAIResponses(selectedScript?.value as keyof Scripts);

    // document.addEventListener("keydown", handleKeyDown);
  });

  const handleScenarioSelectChange = (selectedOption: ScriptOption) => {
    setSelectedScript(selectedOption);
    setAIName(selectedOption.aiName);
    getAIResponses(selectedOption.value as keyof Scripts);
  };

  const handleAISelectChange = (selectedOption: AssistantOption) => {
    setSelectedAI(selectedOption);
    setAIName(selectedOption.aiName);
  };

  const clearAISelections = () => {
    setAIName("");
  };

  // TODO: remove demo script version when we feel comfortable
  const getAIResponses = (selectedOptionValue: keyof Scripts) => {
    scriptIndex.current = 0;
    const scriptVariables: ScriptVariables = { ...config, aiName };
    const script = api.demoScripts.getScript(
      selectedOptionValue,
      scriptVariables,
    );
    const operatorOnlyResponses: Script = script
      ? script.filter((script) => script.role === "operator")
      : [];
    if (scriptToFollow.current) scriptToFollow.current = operatorOnlyResponses;
  };

  return (
    <AIContext.Provider
      value={{
        aiName,
        setAiName: setAIName,
        aiMode,
        setAIMode,
        myFirstName,
        setMyFirstName,
        myLastName,
        setMyLastName,
        aiVoiceAudio,
        selectedAI,
        handleAISelectChange,
        defaultDemoScript,
        selectedScript,
        clearAISelections,
        handleScenarioSelectChange,
      }}
    >
      {children}
    </AIContext.Provider>
  );
};

export { AIContextProvider, AIContext };
