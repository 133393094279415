import React, { ReactNode, useEffect, useState } from "react";
import { FaFileWord, FaFileAlt } from "react-icons/fa";
import { BsFiletypePdf, BsFiletypeXml } from "react-icons/bs";
import axios from "axios";
import {
  Card,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";

interface ObjectData {
  key: string;
  size: number;
  last_modified: string;
  mime_type: string;
}

interface ListResourcesProps {
  refreshSignal: boolean;
}

// API Gateway url to invoke function to generate presigned url
const API_ENDPOINT = process.env.REACT_APP_RESOURCES_ENDPOINT;

const ListResources: React.FC<ListResourcesProps> = ({ refreshSignal }) => {
  const [numOfDownloadedFiles, setNumOfDownloadedFiles] = useState<number>(0);
  const toastErrorDownloading = useToast();
  const [data, setData] = useState<ObjectData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Function to generate the presigned url
  const getPresignedUrl = async (filename: string) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${API_ENDPOINT}/solomonRelihanDemoGetObject`,
        headers: {
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
        },
        params: {
          filename: filename,
        },
      });
      const presignedUrl = response.data.presigned_url;
      if (!presignedUrl) {
        throw new Error("Presigned URL is undefined");
      }
      console.log("Generated presigned URL:", presignedUrl);
      return presignedUrl;
    } catch (error) {
      console.error("Error generating presigned URL:", error);
      throw error;
    }
  };

  // Function to download the selected file using the generated presigned url
  const downloadFromPresignedUrl = async (
    presignedUrl: string,
    filename: string
  ) => {
    try {
      if (!presignedUrl) {
        throw new Error("Invalid presigned URL");
      }
      const url = new URL(presignedUrl); // Validate URL format
      console.log("Downloading from presigned URL:", url.href);

      const response = await axios.get(url.href, {
        responseType: "blob", // Ensure the response is treated as a file
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`Download Progress: ${percentCompleted}%`);
          }
        },
      });

      // Create a link element to download the file
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setNumOfDownloadedFiles(numOfDownloadedFiles + 1);
    } catch (error) {
      console.error("Download failed:", error);
      toastErrorDownloading({
        title: `Error downloading files`,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to orchestrate the download process
  const handleDownload = async (filename: string) => {
    try {
      const presignedUrl = await getPresignedUrl(filename);
      await downloadFromPresignedUrl(presignedUrl, filename);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  function formatFileSize(
    bytes: number,
    si: boolean = true,
    dp: number = 1
  ): string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  const formatFileTypeIcon = (mimeType: string): ReactNode => {
    const iconSize = "1.5em";
    switch (mimeType) {
      case "application/pdf":
        return <BsFiletypePdf size={iconSize} />;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
        return <FaFileWord size={iconSize} />;
      case "application/xml":
      case "text/xml":
        return <BsFiletypeXml size={iconSize} />;
      default:
        return <FaFileAlt size={iconSize} />;
    }
  };

  useEffect(() => {
    // Function to get the list of files
    const fetchList = async () => {
      // GET request: presigned URL
      const response = await axios({
        method: "GET",
        url: `${API_ENDPOINT}/solomonRelihanDemoList`,
        headers: {
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
        },
      });

      return response.data.objects;
    };
    const fetchData = async () => {
      try {
        const objects: ObjectData[] = await fetchList();
        const humanReadableObjects = objects.map((object) => ({
          ...object,
          last_modified: formatDate(object?.last_modified),
        }));
        setData(humanReadableObjects);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refreshSignal]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Card p="15px">
      <CardHeader>
        <Heading size="md" color="brandColors.400">
          Resources
        </Heading>
      </CardHeader>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Filename</Th>
              <Th>Date Modified</Th>
              <Th>size</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item, index) => (
              <Tr
                key={index}
                onClick={() => handleDownload(item.key)}
                cursor="pointer"
              >
                <Td>{formatFileTypeIcon(item.mime_type)}</Td>
                <Td>{item.key}</Td>
                <Td>{item.last_modified}</Td>
                <Td>{formatFileSize(item.size)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ListResources;
