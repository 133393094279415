import { Script } from "./types";

export interface WellPsycheScripts {
  newPatientWellPsyche: Script;
}

export const wellPsycheOptions = [
  {
    value: "newPatientWellPsyche",
    label: "New Patient",
    aiName: "Daisy the Front Desk Closer",
  },
];

export const wellPsycheGroupedOptions = [
  {
    label: "WellPsyche",
    options: wellPsycheOptions,
  },
];

const scripts: WellPsycheScripts = {
  newPatientWellPsyche: [
    {
      role: "caller",
      text: "Hi, I'd like to schedule an appointment.",
    },
    {
      role: "operator",
      text: "Great, I'd be happy to help you schedule an appointment. Are you a new or an existing patient with us at WellPsyche?",
    },
    {
      role: "caller",
      text: "I'm new.",
    },
    {
      role: "operator",
      text: "Welcome to WellPsyche! To schedule your initial appointment, I'll need some information. Could you please provide me with your name?",
    },
    {
      role: "caller",
      text: "{firstName} {lastName}",
    },
    {
      role: "operator",
      text: "Nice to meet you, {firstName}. What type of service are you interested in: Medication Management, Talk Therapy, or a combination of both?",
    },
    {
      role: "caller",
      text: "Just therapy.",
    },
    {
      role: "operator",
      text: "Thank you, {firstName}. Would you prefer to schedule your appointment at our Los Angeles location or the Scottsdale, AZ location? Also, please note that all sessions are conducted online.",
    },
    {
      role: "caller",
      text: "Why do I need to schedule at one location if it's all done online?",
    },
    {
      role: "operator",
      text: "My apologies for any confusion. Since our sessions are online, the physical location may not affect where you can be seen. However, it still helps us to coordinate with the appropriate local clinical team and align with the state licensing regulations for our practitioners. Could you please tell me which state you're located in so we can ensure we match you with a licensed provider in your area?",
    },
    {
      role: "caller",
      text: "Ah okay okay, Arizona please.",
    },
    {
      role: "operator",
      text: "Great, we'll set you up with our clinical team in Scottsdale, AZ. Could you please let me know your availability? We aim to offer appointments within 48 hours. What days and times work best for you?",
    },
    {
      role: "caller",
      text: "Tuesday and Wednesdays in the afternoon",
    },
    {
      role: "operator",
      text: "Perfect, I can check for availability on Tuesdays and Wednesdays in the afternoon. Before I do that, may I have your email address and phone number to complete the booking process and to send you appointment confirmations and reminders?",
    },
    {
      role: "caller",
      text: "anat@gmail.com and my phone number is 480-555-1212",
    },
    {
      role: "operator",
      text: "Thank you for providing your contact information, {firstName}. I will now check for available appointment slots on Tuesday and Wednesday afternoons and get back to you shortly. In the meantime, could you please tell me if you have a preference for a male or female therapist, or if you have no preference at all?",
    },
    {
      role: "caller",
      text: "no preference, thanks",
    },
    {
      role: "operator",
      text: "Okay, {firstName}. I'll go ahead and find the next available appointments for therapy on Tuesday and Wednesday afternoons and reserve one for you. Please hold for a moment while I look that up.",
    },
    {
      role: "operator",
      text: "{firstName}, sorry but I don't have good news for you. We don't have an available therapist for you but we can do a psychiatric evaluation for you.",
    },
    {
      role: "caller",
      text: "Oh, okay, no worries. Actually my mother in law Joyce needs psychiatric care and I didn't know you did that.",
    },
    {
      role: "operator",
      text: "Of course, we can help arrange that for Joyce. To proceed, may I have Joyce's date of birth, and does she have insurance coverage from any of the providers we are in-network with? These include Aetna, Anthem, Beacon Health, Blue Shield, Blue Cross, Cigna, PNOA, Optum, and United Healthcare.",
    },
    {
      role: "caller",
      text: "January 3, 1943. She's got Blue Cross Blue Shield.",
    },
    {
      role: "operator",
      text: "Perfect, we are in-network with Blue Cross Blue Shield. Would you like to book a psychiatry appointment, a therapy appointment, or both for Joyce?",
    },
    {
      role: "caller",
      text: "pyschiatry",
    },
    {
      role: "operator",
      text: "Understood. Would you prefer to schedule Joyce's appointment at our Los Angeles location or at our Scottsdale, Arizona location?",
    },
    {
      role: "caller",
      text: "Okay, yes Scottsdale.",
    },
    {
      role: "operator",
      text: "Excellent choice. Our Scottsdale location can certainly provide Joyce with the psychiatric care she needs. I will text you with the instructions to enroll her and how to verify her insurance. Could you please provide me with a mobile phone number where I can send this information?",
    },
    {
      role: "caller",
      text: "602-555-1212",
    },
    {
      role: "operator",
      text: "Thank you. I will send a text message to 602-555-1212 with the instructions for enrollment and insurance verification for Joyce. To find a suitable time, could you tell me what days of the week and times are generally most convenient for Joyce?",
    },
    {
      role: "caller",
      text: "Friday afternoons are the only day we can take her.",
    },
    {
      role: "operator",
      text: "Got it. Let me check our availability for Friday afternoons. Please hold for a moment while I find the next available slot.",
    },
    {
      role: "operator",
      text: "We've got 3pm on 12/22 open.",
    },
    {
      role: "caller",
      text: "Okay great. That works.",
    },
    {
      role: "operator",
      text: "Perfect. Is there anything else I can assist you with?",
    },
    {
      role: "caller",
      text: "No, thank you.",
    },
    {
      role: "operator",
      text: "Thanks for calling. We'll see Joyce very soon. Goodbye.",
    },
  ],
};

export default scripts;
