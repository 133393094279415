import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Container,
  Link as ChakraLink,
  Skeleton, // Import Skeleton from Chakra UI
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const AIComingSoonPage: React.FC = () => {
  return (
    <Container centerContent>
      <Box textAlign="center" py={10} px={6}>
        {/* <Image
          borderRadius="full"
          boxSize="150px"
          src="https://via.placeholder.com/150"
          alt="404 Graphic"
          mb={4}
        /> */}
        <Heading display="inline-block" as="h2" size="2xl" color="teal.500">
          Coming Soon
        </Heading>
        {/* Skeleton component added here */}
        <Skeleton
          startColor="teal.500"
          endColor="teal.200"
          height="20px"
          my={4}
        />
        <Text fontSize="18px" mt={3} mb={2}>
          In the meantime, upload some resources to inform your AI assistant.
        </Text>
        <ChakraLink
          as={RouterLink}
          to="/resources"
          style={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
        >
          <Button
            colorScheme="teal"
            bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
            color="white"
            variant="solid"
          >
            Upload
          </Button>
        </ChakraLink>
      </Box>
    </Container>
  );
};

export default AIComingSoonPage;
