import React, { useRef, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Input,
  Progress,
  Square,
  Text,
  useToast,
} from "@chakra-ui/react";

interface UploadResourceProps {
  refreshData: Function;
}

const UploadResource: React.FC<UploadResourceProps> = ({ refreshData }) => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentUploadFile, setCurrentUploadFile] = useState<File | null>(null);
  const [numOfUploadedFiles, setNumOfUploadedFiles] = useState<number>(0);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const toastSucessfulUpload = useToast();
  const toastErrorUploading = useToast();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || new FileList();
    setSelectedFiles(files);
  };

  // API Gateway url to invoke function to generate presigned url
  const API_ENDPOINT = process.env.REACT_APP_RESOURCES_ENDPOINT;

  // Function to generate the presigned url
  const getPresignedUrl = async (file: File) => {
    // GET request: presigned URL
    const response = await axios({
      method: "GET",
      url: `${API_ENDPOINT}/solomonRelihanDemoUpload`,
      headers: {
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
      },
      params: {
        filename: file?.name,
        content_type: file?.type,
      },
    });
    const presignedUrl = response.data.presignedUrl;
    return presignedUrl;
  };

  // Function to upload the selected file using the generated presigned url
  const uploadToPresignedUrl = async (presignedUrl: string, file: File) => {
    setCurrentUploadFile(file);
    const uploadResponse = axios
      .put(presignedUrl, file, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": file?.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setUploadProgress(percentCompleted);
            console.log(`Upload Progress: ${percentCompleted}%`);
          }
        },
      })
      .catch((error) => {
        console.error("Upload failed:", error);
        toastErrorUploading({
          title: `Error uploading files`,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      })
      .then(() => {
        toastSucessfulUpload({
          title: `Successfully uploaded ${file.name}`,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        refreshData();
        setNumOfUploadedFiles(numOfUploadedFiles + 1);
      });
    console.log(uploadResponse);
  };

  // Function to orchestrate the upload process
  const handleUpload = async () => {
    try {
      // Ensure a file is selected
      if (!selectedFiles) {
        console.error("No file(s) selected.");
        return;
      }
      for (const file of selectedFiles) {
        const presignedUrl = await getPresignedUrl(file);
        await uploadToPresignedUrl(presignedUrl, file);
      }
    } catch (error) {
      // Handle error
      console.error("Error uploading file:", error);
    } finally {
      if (fileInput.current?.value) {
        fileInput.current.value = "";
      }
      setSelectedFiles(null);
      setCurrentUploadFile(null);
      setUploadProgress(0);
    }
  };

  return (
    <Card variant="outline">
      <CardHeader>
        <Heading size="md" color="brandColors.400">
          Upload a file
        </Heading>
      </CardHeader>
      <CardBody>
        <Flex alignItems="center" gap="2" pb="3px">
          <Input
            p="3px"
            ref={fileInput}
            type="file"
            multiple
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Square>
            <Container centerContent justifyContent={"center"}>
              <Button onClick={handleUpload}>Upload</Button>
            </Container>
          </Square>
        </Flex>
        {currentUploadFile && (
          <Box>
            <Flex alignItems="center" gap="2" pb="3px">
              <Heading size="xs" textTransform={"uppercase"} p="2">
                Filename
              </Heading>
              <Text fontSize="md">{currentUploadFile?.name}</Text>
              <Progress value={uploadProgress} />
            </Flex>
          </Box>
        )}
        {numOfUploadedFiles > 0 && (
          <Box>
            <Text fontSize="md" pl="2px">
              Successfully uploaded {numOfUploadedFiles} files!
            </Text>
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export default UploadResource;
