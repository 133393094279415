import {
  Grid,
  Box,
  Heading,
  Button,
  Text,
  Divider,
  Image,
  useColorMode,
  AspectRatio,
  Card,
  CardBody,
  Highlight,
  CardHeader,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import {
  // BiSolidCamera,
  // BiMicrophone,
  // BiMicrophoneOff,
  BiPhoneCall,
  BiStop,
} from "react-icons/bi";

import avatars, { ImageLookup } from "../assets/avatars/lg";
import { ContextProps, SocketContext } from "./Context";
import { AIContext, AIContextProps } from "./AIContext";
import AutoScrollingBox from "./AutoScrollingBox";

const BRAND_OPTIONS_KEY = process.env.REACT_APP_BRAND_OPTIONS_KEY;
const DEMO_TITLE = process.env.REACT_APP_DEMO_TITLE;

const aiAvatar = avatars[BRAND_OPTIONS_KEY as keyof ImageLookup];

const StaticAvatar: React.FC = () => {
  const { colorMode } = useColorMode();
  const context = useContext<ContextProps | null>(SocketContext);
  const aiContext = useContext<AIContextProps | null>(AIContext);

  /* TODO: use proper type for this. */
  const {
    isCalling,
    aiVoiceAudio,
    aiTranscript,
    lastAITranscriptWord,
    toggleConversation,
  }: any = context;
  const { aiName }: any = aiContext;
  // const [aiLastWord] = useState<string>(aiTranscript[-1]);

  /* Future icon order when call is in session */
  // Speaker * Camera * Mute * Share * End

  const boxMargin = [1, 2, 6, 10];
  const nameSize = { base: "md", lg: "xl" };
  const fontSizeTranscript = [16, 20, 24, 32];
  const isSmallScreen = useBreakpointValue(
    { base: true, md: false },
    { ssr: false },
  );

  return (
    <Box margin={boxMargin} bgColor="grey.50">
      <Grid bgColor="grey.50">
        <Card variant="outline">
          <CardHeader pb="0px">
            <Heading size={nameSize} color="brandColors.500">
              {aiName || "Your AI Assistant"}{" "}
              {isSmallScreen && DEMO_TITLE && `- ${DEMO_TITLE}`}
            </Heading>
          </CardHeader>
          <CardBody>
            <AspectRatio ratio={3 / 2}>
              <Box
                borderTopLeftRadius="lg"
                borderTopRightRadius="lg"
                overflow="hidden"
                boxShadow="md"
              >
                {aiAvatar ? (
                  <Image
                    src={aiAvatar}
                    alt="Descriptive text about the image"
                    borderTopLeftRadius="lg"
                    borderTopRightRadius="lg"
                    boxShadow="md"
                    objectFit="cover" // This ensures the image covers the area without stretching
                  />
                ) : (
                  <Box></Box>
                )}
              </Box>
            </AspectRatio>
            <Button
              leftIcon={isCalling ? <BiStop /> : <BiPhoneCall />}
              colorScheme={isCalling ? "red" : "whatsapp"}
              variant="solid"
              onClick={toggleConversation}
              justifyContent="center"
              borderRadius="unset"
              borderBottomLeftRadius="10px"
              borderBottomRightRadius="10px"
              width="100%"
            >
              {isCalling ? "End the call" : "Make a web call"}
            </Button>
          </CardBody>
        </Card>
        <audio autoPlay ref={aiVoiceAudio}></audio>
        <Divider
          pt="10px"
          color={colorMode === "light" ? "gray.600" : "brandColors.800"}
        />
        <AutoScrollingBox>
          {aiTranscript && (
            <Text
              color={colorMode === "light" ? "gray.600" : "brandColors.200"}
              fontSize={fontSizeTranscript}
            >
              <Highlight
                query={lastAITranscriptWord}
                styles={{ px: "1", py: "1", bg: "orange.100" }}
              >
                {aiTranscript}
              </Highlight>
            </Text>
          )}
        </AutoScrollingBox>
      </Grid>
    </Box>
  );
};

export default StaticAvatar;
