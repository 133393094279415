import React, { useRef, useEffect } from "react";
import { Box } from "@chakra-ui/react";

interface AutoScrollingBoxProps {
  children: React.ReactNode;
}

const AutoScrollingBox: React.FC<AutoScrollingBoxProps> = ({ children }) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
  }, [children]); // This effect runs every time `children` changes.

  return (
    <Box
      maxH="xs"
      overflowY="auto"
      ref={bottomRef}
      ml={"16px"}
      mr={"8px"}
      mb={"12px"}
    >
      {children}
    </Box>
  );
};

export default AutoScrollingBox;
