import {
  Grid,
  Box,
  Heading,
  Text,
  useColorMode,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { WindupChildren } from "windups";

import { useMyVideo } from "video/video";

import { ContextProps, SocketContext } from "../components/Context";
import StaticAvatar from "../components/StaticAvatar";
import MyVideo from "../components/MyVideo";

const VideoPlayer: React.FC = () => {
  const { colorMode } = useColorMode();
  const context = useContext<ContextProps | null>(SocketContext);

  /* TODO: use proper type for this. */
  const { callAccepted, myVideo, userVideo, callEnded, myStream, call }: any =
    context;
  useMyVideo(myVideo, myStream);

  // TODO: Highlight only the last word
  // const [aiLastWord] = useState<string>(aiTranscript[-1]);

  /* Future icon order when call is in session */
  // Speaker * Camera * Mute * Share * End

  const gridMarginTop = { base: 0.5, md: 2, lg: 4 };
  const boxMargin = [1, 2, 6, 10];
  const nameSize = { base: "md", lg: "xl" };
  const isSmallScreen = useBreakpointValue(
    { base: true, md: false },
    { ssr: false },
  );

  const presentMobileLayout = () => {
    return (
      <Grid
        mt={gridMarginTop}
        justifyContent="center"
        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
      >
        {/* AI "video" */}
        <StaticAvatar />
        {/* user's video */}
        {callAccepted && !callEnded && (
          <Box margin={boxMargin} minH="100vh">
            <Grid>
              <Heading size={nameSize} color="brandColors.500">
                {call.name || "Name"}
              </Heading>
              <Heading color="brandColors.500">{call.name || "Name"}</Heading>
              <video
                playsInline
                muted
                ref={userVideo}
                autoPlay
                width="600"
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  outline: "none",
                }}
              />
              <WindupChildren>
                <Text
                  color={colorMode === "light" ? "gray.600" : "brandColors.200"}
                  fontSize={32}
                >
                  {/* {userTranscript} */}
                </Text>
              </WindupChildren>
            </Grid>
          </Box>
        )}
      </Grid>
    );
  };

  // Grid is you, then the AI
  const presentDesktopLayout = () => {
    return (
      <Grid
        mt={gridMarginTop}
        justifyContent="center"
        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
      >
        {/* my video */}
        {myStream && <MyVideo />}
        {/* user's video */}
        {callAccepted && !callEnded && (
          <Box margin={boxMargin}>
            <Grid>
              <Heading size={nameSize} color="brandColors.500">
                {call.name || "Name"}
              </Heading>
              <Heading color="brandColors.500">{call.name || "Name"}</Heading>
              <video
                playsInline
                muted
                ref={userVideo}
                autoPlay
                width="600"
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  outline: "none",
                }}
              />
              <WindupChildren>
                <Text
                  color={colorMode === "light" ? "gray.600" : "brandColors.200"}
                  fontSize={32}
                >
                  {/* {userTranscript} */}
                </Text>
              </WindupChildren>
            </Grid>
          </Box>
        )}
        {/* AI video */}
        <StaticAvatar />
      </Grid>
    );
  };

  return isSmallScreen ? presentMobileLayout() : presentDesktopLayout();
};

export default VideoPlayer;
