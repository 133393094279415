import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  MutableRefObject,
  Dispatch,
  ReactElement,
  SetStateAction,
} from "react";

import { io, Socket } from "socket.io-client";

import Peer, { Instance as PeerInstance } from "simple-peer";

import { retellWebClient } from "api/clients";
import { defaultDemoScriptOption } from "api/demoScripts";

const config = {
  firstName: process.env.REACT_APP_MY_FIRST_NAME || "",
  lastName: process.env.REACT_APP_MY_LAST_NAME || "",
  socketUrl: process.env.REACT_APP_SOCKET_URL || "http://localhost:8000",
  defaultDemoScript: defaultDemoScriptOption,
  convoAgentId: process.env.REACT_APP_CONVO_AGENT_ID || "",
  convoRegisterCallUrl: process.env.REACT_APP_CONVO_BACKEND_DOMAIN
    ? `https://${process.env.REACT_APP_CONVO_BACKEND_DOMAIN}/register-call`
    : "http://localhost:8080/register-call",
};

interface Props {
  children: React.ReactElement;
}

interface CallData {
  isReceivingCall: boolean;
  from: string;
  name: string;
  signal: any;
}

interface RegisterCallResponse {
  callId?: string;
  sampleRate: number;
}

interface TranscriptWord {
  word: string;
  start: number;
  end: number;
}

interface TranscriptSegment {
  role: "user" | "agent";
  content: string;
  words: TranscriptWord[];
}

type Transcript = TranscriptSegment[];

export interface ContextProps {
  /* Call Info */
  call: CallData;
  callAccepted: boolean;
  callEnded: boolean;
  isCalling: boolean;
  /* Video and Audio */
  myVideo: MutableRefObject<HTMLVideoElement | null>;
  userVideo: MutableRefObject<HTMLVideoElement | null>;
  aiVoiceAudio: MutableRefObject<HTMLAudioElement | null>;
  myStream: MediaStream | null;

  /* User Info */
  myFirstName: string;
  setMyFirstName: Dispatch<SetStateAction<string>>;
  myLastName: string;
  setMyLastName: Dispatch<SetStateAction<string>>;
  myId: string;

  /* Call Functions */
  callUser: (id: string) => void;
  callAI: () => void;
  leaveCall: () => void;
  answerCall: () => void;
  toggleConversation: () => void;

  /* Transcripts */
  aiTranscript: string;
  myTranscript: string;
  lastAITranscriptWord: string;
  lastMyTranscriptWord: string;
}

const SocketContext = createContext<ContextProps | null>(null);
const socket: Socket = io(config.socketUrl);

const ContextProvider: React.FC<Props> = ({ children }): ReactElement => {
  /* Calls State */
  const [callAccepted, setCallAccepted] = useState<boolean>(false);
  const [callEnded, setCallEnded] = useState<boolean>(false);

  const [isCalling, setIsCalling] = useState(false); // todo - use accepted and ended from context props instead

  /* Streams State*/
  const [myStream, setMyStream] = useState<MediaStream | null>(null);
  // const [aiStream, setAIStream] = useState<MediaStream | null>(null);

  /* My Profile State */
  const [myFirstName, setMyFirstName] = useState<string>(config.firstName);
  const [myLastName, setMyLastName] = useState<string>(config.lastName);

  /* Transcription state */
  const [, setFullTranscript] = useState<Transcript>([]);
  const [aiTranscript, setAITranscript] = useState<string>("");
  const [myTranscript, setMyTranscript] = useState<string>("");
  const [lastAITranscriptWord, setLastAITranscriptWord] = useState<string>("");
  const [lastMyTranscriptWord, setLastMyTranscriptWord] = useState<string>("");

  // TODO: recordButton
  // const [recordButtonImage, setRecordButtonImage] = useState<string>();
  const [call, setCall] = useState<CallData>({} as CallData);
  const [me, setMe] = useState<string>("");

  const myVideo = useRef<HTMLVideoElement | null>(null);
  const userVideo = useRef<HTMLVideoElement | null>(null);
  const aiVoiceAudio = useRef<HTMLAudioElement | null>(null);
  const connectionRef = useRef<PeerInstance | null>(null);

  // TODO: check if this is needed to record what went down:
  // https://stackoverflow.com/questions/76769141/how-to-record-audio-from-microphone-and-speaker-in-reactjs-with-typescript-and-s

  useEffect(() => {
    // Setup event listeners
    retellWebClient.on("conversationStarted", () => {
      console.log("conversationStarted");
    });

    retellWebClient.on("audio", (audio: Uint8Array) => {
      console.log("There is audio");
    });

    retellWebClient.on("conversationEnded", ({ code, reason }) => {
      console.log("Closed with code:", code, ", reason:", reason);
      setIsCalling(false); // Update button to "Start" when conversation ends
    });

    retellWebClient.on("error", (error) => {
      console.error("An error occurred:", error);
      setIsCalling(false); // Update button to "Start" in case of error
    });

    retellWebClient.on("update", (update) => {
      // TODO: Print live transcript as needed
      // https://docs.retellai.com/make-calls/web-call-setup#transcript-update
      handleTranscriptUpdate(update?.transcript);
      console.log("update", update);
    });
    /*
    Declare functions only used in #useEffect hook
    See https://react.dev/reference/react/useEffect#removing-unnecessary-function-dependencies
    */
    const setupMedia = () => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert("Your browser does not support recording!");
        return;
      }
      // browser supports getUserMedia

      // TODO: change image in button
      // recordButtonImage.src = `/assets/${mediaRecorder && mediaRecorder.state === 'recording' ? 'microphone' : 'stop'}.png`;

      navigator.mediaDevices
        .getUserMedia({ video: { width: 300, height: 200 }, audio: false })
        .then((currentStream) => {
          setMyStream(currentStream);
        })
        .catch((error) => {
          // if (error.name === "OverconstrainedError") {
          // console.error(
          //     `The resolution ${constraints.video.width.exact}x${constraints.video.height.exact} px is not supported by your device.`,
          // );
          if (error === "AlreadyIntialized") {
            console.log("Already initialized - continuing.");
          } else if (error.name === "MicrophoneNotIntialized") {
            console.error("Microphone not initialized!! Oops");
          } else if (error.name === "NotAllowedError") {
            console.error(
              "You need to grant this page permission to access your camera and microphone.",
            );
          } else {
            console.error(`The following error occurred: ${error.name}`, error);
            // TODO: change image in button
            // recordButtonImage.src = '/images/microphone.png';
          }
        });
    };

    const handleTranscriptUpdate = async (transcript: TranscriptSegment[]) => {
      setFullTranscript(transcript);

      const lastUserTranscriptSegment = transcript.findLast(
        (transcriptSegment: TranscriptSegment) =>
          transcriptSegment?.role === "user",
      );
      if (lastUserTranscriptSegment?.content) {
        await setMyTranscript(lastUserTranscriptSegment?.content);
      }

      const lastAgentTranscriptSegment = transcript.findLast(
        (transcriptSegment: TranscriptSegment) =>
          transcriptSegment?.role === "agent",
      );
      if (lastAgentTranscriptSegment?.content)
        await setAITranscript(lastAgentTranscriptSegment?.content);

      /* Last word */
      const lastMyWord =
        lastUserTranscriptSegment?.words[
          lastUserTranscriptSegment?.words.length - 1
        ]?.word;
      if (lastMyWord) setLastMyTranscriptWord(` ${lastMyWord} `);
      const lastAIWord =
        lastAgentTranscriptSegment?.words[
          lastAgentTranscriptSegment?.words.length - 1
        ]?.word;
      if (lastAIWord) setLastAITranscriptWord(` ${lastAIWord} `);
      //console.log(transcript);
    };

    /* Begin Business Logic */
    setupMedia();
    socket.on("me", (id) => {
      setMe(id);
    });
    socket.on("callUser", ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });
    return () => {
      // stopRecording();
    };
  }, []);

  async function registerCall(agentId: string): Promise<RegisterCallResponse> {
    try {
      const response = await fetch(config.convoRegisterCallUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          agentId: agentId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data: RegisterCallResponse = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  const toggleConversation = async () => {
    if (isCalling) {
      retellWebClient.stopConversation();
    } else {
      const registerCallResponse = await registerCall(config.convoAgentId);
      if (registerCallResponse.callId) {
        retellWebClient
          .startConversation({
            callId: registerCallResponse.callId,
            sampleRate: registerCallResponse.sampleRate,
            enableUpdate: true,
          })
          .catch(console.error);
        setIsCalling(true); // Update button to "Stop" when conversation starts
      }
    }
  };

  const answerCall = async () => {
    setCallAccepted(true);

    const localStream = myStream ? myStream : undefined;
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: localStream,
    });
    peer.on("signal", (data: any) => {
      socket.emit("answerCall", { signal: data, to: call.from });
    });
    peer.on("userToUserStream", (currentStream: MediaStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });
    peer.signal(call.signal);
    connectionRef.current = peer;
  };

  const callAI = async () => {
    // console.log("callAI method")
    // const localStream = aiStream ? aiStream : undefined
    // const peer = new Peer({ initiator: true, trickle: false, stream: localStream });
    // peer.on('signal', (data: any) => {
    //     socket.emit('callAI', { signalData: data, from: me, name: myFirstName });
    // });
    // peer.on('userToAIStream', (currentStream: MediaStream) => {
    //     // playTextToSpeech();
    // });
    // socket.on('callAccepted', (signal: any) => {
    //     setCallAccepted(true);
    //     peer.signal(signal);
    // });
    // connectionRef.current = peer;
  };

  const callUser = (id: string) => {
    const localStream = myStream ? myStream : undefined;
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: localStream,
    });
    peer.on("signal", (data: any) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name: myFirstName,
      });
    });
    peer.on("userToUserStream", (currentStream: MediaStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });
    socket.on("callAccepted", (signal: any) => {
      setCallAccepted(true);
      peer.signal(signal);
    });
    connectionRef.current = peer;
  };

  const leaveCall = () => {
    setCallEnded(true);
    retellWebClient.stopConversation();
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    window.location.reload();
  };

  return (
    <SocketContext.Provider
      value={{
        call,
        callAccepted,
        isCalling,
        myVideo,
        userVideo,
        aiVoiceAudio,
        myStream,
        myFirstName,
        setMyFirstName,
        myLastName,
        setMyLastName,
        callEnded,
        myId: me,
        callUser,
        callAI,
        leaveCall,
        answerCall,
        toggleConversation,
        aiTranscript,
        myTranscript,
        lastAITranscriptWord,
        lastMyTranscriptWord,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
