import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  Heading,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  // MenuDivider,
  // MenuItem,
  // MenuList,
  Image,
  useColorMode,
  useBreakpointValue,
  Link as ChakraLink,
  Spacer,
} from "@chakra-ui/react";
import {
  FiHome,
  FiCalendar,
  FiFileText,
  // FiTrendingUp,
  // FiCompass,
  // FiStar,
  FiSmartphone,
  FiMenu,
  FiBell,
  // FiChevronDown,
} from "react-icons/fi";
import { IconType } from "react-icons";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import logos, { ImageLookup } from "assets/logos";
import footers from "assets/footers";
import { Link as RouterLink } from "react-router-dom";

import Copyright from "components/Copyright";

// Todo: make this configurable
// import DeploymentInProgress from "./notifications/DeploymentInProgress";

/* FEATURE FLAGS */
const FEATURE_FLAG_RESOURCES_ENABLED =
  Number(process.env.REACT_APP_FEATURE_FLAG_RESOURCES_ENABLED) || null;
const FEATURE_FLAG_SHARE_OR_TRY_MOBILE =
  Number(process.env.REACT_APP_FEATURE_FLAG_SHARE_OR_TRY_MOBILE) || null;

/* TODO: Move the following configurations to a new file called branding.ts */
/* Logos */
const BRAND_OPTIONS_KEY = process.env.REACT_APP_BRAND_OPTIONS_KEY || "";
const logoImage = logos[BRAND_OPTIONS_KEY as keyof ImageLookup];
const logoImage2 = null; //logos["DTAI"];

// TODO: support footers properly
const footerLogo = footers[BRAND_OPTIONS_KEY];

const DEMO_TITLE = process.env.REACT_APP_DEMO_TITLE;

const companyName = process.env.REACT_APP_COMPANY_NAME || "Decision Tree AI";
const myFirstName = process.env.REACT_APP_MY_FIRST_NAME;
const myLastName = process.env.REACT_APP_MY_LAST_NAME;

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
  link: string;
  // proper prop for this
  onClick?: any;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const InitialLinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome, link: "/" },
  { name: "Make this real", icon: FiCalendar, link: "/calendly" },
  // { name: "The Vision", icon: FiCompass, link: "/vision"},
  // { name: "Favorites", icon: FiStar },
  // { name: "Settings", icon: FiSettings, link: "/coming-soon" },
];
let LinkItems = InitialLinkItems;

if (FEATURE_FLAG_RESOURCES_ENABLED) {
  LinkItems = [
    ...InitialLinkItems.slice(0, 1),
    { name: "Add resources", icon: FiFileText, link: "/resources" },
    ...InitialLinkItems.slice(1),
  ];
}

if (FEATURE_FLAG_SHARE_OR_TRY_MOBILE) {
  LinkItems.push({
    name: "Share or Try mobile",
    icon: FiSmartphone,
    link: "/share",
  });
}

// Desktop
const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const backgroundColor = useColorModeValue("white", "gray.900");
  return (
    <Box
      transition="3s ease"
      bg={backgroundColor}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      {/* Todo: make this configurable */}
      {/* <DeploymentInProgress /> */}
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image
          src={logoImage}
          alt={companyName}
          //objectFit="cover"
          maxWidth="100px"
          maxHeight="50px"
        />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} link={link.link}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, link, children, ...rest }: NavItemProps) => {
  return (
    <ChakraLink
      as={RouterLink}
      to={link}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "brandColors.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </ChakraLink>
  );
};

const HeaderNav = ({ onOpen, ...rest }: MobileProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const backgroundColor = useColorModeValue("white", "gray.900");
  const borderBottomColor = useColorModeValue("gray.200", "gray.700");
  const isSmallScreen = useBreakpointValue(
    { base: true, md: false },
    { ssr: false },
  );
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={backgroundColor}
      borderBottomWidth="1px"
      borderBottomColor={borderBottomColor}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image
        display={{ base: "flex", md: "none" }}
        src={logoImage}
        alt={companyName}
        objectFit="cover"
        maxWidth="100px"
        maxHeight="45px"
      />
      {!isSmallScreen && (
        <Heading
          display="inline-block"
          as="h4"
          size="md"
          paddingRight="20px"
          color="brandColors.400"
        >
          {DEMO_TITLE}
        </Heading>
      )}
      {logoImage2 && (
        <Image
          src={logoImage2}
          alt="Decision Tree AI Logo"
          objectFit="cover"
          maxWidth="150px"
        />
      )}

      <HStack spacing={{ base: "0", md: "2" }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />

        <IconButton
          onClick={toggleColorMode}
          variant="ghost"
          aria-label="toggle darkmode"
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
        ></IconButton>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">
                    {myFirstName} {myLastName}
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                {/* <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box> */}
              </HStack>
            </MenuButton>
            {/* <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem>Sign out</MenuItem>
            </MenuList> */}
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const Footer = ({ ...rest }) => {
  const backgroundColor = useColorModeValue("white", "gray.900");
  const borderBottomColor = useColorModeValue("gray.200", "gray.700");
  return (
    footerLogo && (
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="60px"
        alignItems="center"
        bg={backgroundColor}
        borderBottomWidth="1px"
        borderBottomColor={borderBottomColor}
        {...rest}
      >
        <h1>Powered By</h1>
        <Image
          src={footerLogo}
          alt={companyName}
          objectFit="cover"
          maxWidth="300px"
          maxHeight="40px"
          pl="30px"
        />
        <Spacer />
        <Copyright>Decision Tree AI. All rights reserved.</Copyright>
      </Flex>
    )
  );
};

const FullLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const colorModeValue = useColorModeValue("gray.100", "gray.900");

  return (
    <Flex direction="column" minH="100vh" bg={colorModeValue}>
      <Box>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <HeaderNav onOpen={onOpen} />
      </Box>
      <Box flex="1" ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
      <Footer padding="5" />
    </Flex>
  );
};

export default FullLayout;
