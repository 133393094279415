import React, { useState } from "react";
import { Card, StackDivider, VStack } from "@chakra-ui/react";
import UploadResource from "components/resources/UploadResource";
import ListResources from "components/resources/ListResources";

const ResourcesPage: React.FC = () => {
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false);

  const refreshData = () => {
    setRefreshSignal((prev) => !prev); // Toggle the refresh signal
  };
  return (
    <VStack
      divider={<StackDivider borderColor="gray.200" />}
      spacing={4}
      align="stretch"
    >
      <Card p="15px">
        <UploadResource refreshData={refreshData} />
      </Card>
      <Card>
        <ListResources refreshSignal={refreshSignal} />
      </Card>
    </VStack>
  );
};

export default ResourcesPage;
