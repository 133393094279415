import React, { useState } from "react";
import { Button } from "@chakra-ui/react";

import { BiCheck, BiShare } from "react-icons/bi";

type CopyButtonProps = {
  resourceName?: string;
  textToCopy: string;
  size?: string;
};

const CopyButton: React.FC<CopyButtonProps> = ({
  resourceName = "",
  textToCopy,
  size,
}) => {
  const [isTextCopied, setIsTextCopied] = useState<boolean>(false);
  return (
    <Button
      size={size}
      colorScheme="teal"
      bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
      color="white"
      variant={isTextCopied ? "solid" : "outline"}
      leftIcon={isTextCopied ? <BiCheck /> : <BiShare />}
      onClick={() => {
        navigator.clipboard.writeText(textToCopy);
        setIsTextCopied(true);
      }}
    >
      {isTextCopied ? `Copied ${resourceName}!` : `Share ${resourceName}`}
    </Button>
  );
};

export default CopyButton;
