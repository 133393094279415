import { Grid, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import ShareLink from "components/share-cards/ShareLink";
import QRCode from "components/share-cards/ShareQRCode";
import ShareImage from "components/share-cards/ShareImage";
// import ShareEmbed from "components/share-cards/ShareEmbed";

const ShareAndTryMobilePage: React.FC = () => {
  const gridMarginTop = { base: 0.5, md: 2, lg: 4 };

  const gridTemplateColumns = useBreakpointValue({
    base: "repeat(1, 1fr)",
    sm: "repeat(1, 1fr)",
    md: "repeat(1, 1fr)",
    lg: "repeat(1, 1fr)",
    xl: "repeat(3git , 1fr)",
  });

  return (
    <>
      <Grid
        gap={6}
        templateColumns={gridTemplateColumns}
        marginTop={gridMarginTop}
      >
        <ShareLink />
        <QRCode />
        <ShareImage />
        {/* Todo, share embed with syntax highlighting */}
        {/* <ShareEmbed /> */}
        {/* 2024-04-06T16:57:29.995Z [INFO]: > client@0.1.0 build
                                 > react-app-rewired build
2024-04-06T16:57:31.207Z [INFO]: Creating an optimized production build...
2024-04-06T16:57:36.749Z [INFO]: Failed to compile.
2024-04-06T16:57:36.755Z [INFO]: Module not found: Error: Can't resolve 'react-syntax-highlighter' in '/codebuild/output/src331407594/src/webrtc-react-video-chat/client/src/components/share-cards'
2024-04-06T16:57:36.859Z [ERROR]: !!! Build failed
2024-04-06T16:57:36.859Z [ERROR]: !!! Error: Command failed with exit code 1 */}
      </Grid>
    </>
  );
};

export default ShareAndTryMobilePage;
