import eldepal from "../logos/eldepal.png";
import frontDeskCloser from "../logos/DTAI-Logotype-Color.png";
import DTAINotFound from "./decisionTree.png";
import CroppedDTAILogo from "../logos/cropped-DTAI-Logo.png";
import OZOLogo from "../logos/ozologo.png";
import venturesConcierge from "./decisionTree.png";

// TODO: FILL IN THE REST OF THESE, RIGHT NOW THEY'RE LOGOS

export interface ImageLookup {
  [key: string]: string;
}

const images: ImageLookup = {
  eldepal: eldepal,
  frontDeskCloser: frontDeskCloser,
  DTAI: CroppedDTAILogo,
  OZO: OZOLogo,
  venturesConcierge: venturesConcierge,
  MASTER: DTAINotFound,
};
export default images;
