import { GroupBase } from "chakra-react-select";

import { AssistantOption } from "./types";

import asuAssistantOptions, { asuGroupedOptions } from "./asuAssistants";
import axiomCareAssistantOptions, {
  axiomCareGroupedOptions,
} from "./axiomCareAssistants";
import eldepalAssistantOptions, {
  eldepalGroupedOptions,
} from "./eldepalAssistants";
import taprootAssistantOptions, {
  taprootGroupedOptions,
} from "./taprootAssistants";
import ozziAssistantOptions, { ozziGroupedOptions } from "./ozziAssistants";
import sisterLoveAssistantOptions, {
  sisterLoveGroupedOptions,
} from "./sisterLoveAssistants";
import solomonRelihanAssistantOptions, {
  solomonRelihanGroupedOptions,
} from "./solomonRelihanAssistants";
import venturesConciergeAssistantOptions, {
  venturesConciergeGroupedOptions,
} from "./venturesConciergeAssistants";
import wellPsycheAssistantOptions, {
  wellPsycheGroupedOptions,
} from "./wellPsycheAssistants";

const masterGroupedOptions: GroupBase<AssistantOption>[] = [
  {
    label: "asu",
    options: asuAssistantOptions,
  },
  {
    label: "eldepal",
    options: eldepalAssistantOptions,
  },
  {
    label: "taproot",
    options: taprootAssistantOptions,
  },
  {
    label: "OZO",
    options: ozziAssistantOptions,
  },
  {
    label: "sisterLove",
    options: sisterLoveAssistantOptions,
  },
  {
    label: "solomonRelihan",
    options: solomonRelihanAssistantOptions,
  },
  {
    label: "venturesConcierge",
    options: venturesConciergeAssistantOptions,
  },
  {
    label: "WellPsyche",
    options: wellPsycheAssistantOptions,
  },
];

let defaultOption: AssistantOption | null;

let groupedOptions: GroupBase<AssistantOption>[];
switch (process.env.REACT_APP_DEMO_OPTIONS) {
  case "asu":
    groupedOptions = asuGroupedOptions;
    defaultOption = asuAssistantOptions[0];
    break;
  case "axiomcare":
    groupedOptions = axiomCareGroupedOptions;
    defaultOption = axiomCareAssistantOptions[0];
    break;
  case "eldepal":
    groupedOptions = eldepalGroupedOptions;
    defaultOption = eldepalAssistantOptions[0];
    break;
  case "taproot":
    groupedOptions = taprootGroupedOptions;
    defaultOption = taprootAssistantOptions[0];
    break;
  case "OZO":
    groupedOptions = ozziGroupedOptions;
    defaultOption = ozziAssistantOptions[0];
    break;
  case "sisterLove":
    groupedOptions = sisterLoveGroupedOptions;
    defaultOption = sisterLoveAssistantOptions[0];
    break;
  case "solomonRelihan":
    groupedOptions = solomonRelihanGroupedOptions;
    defaultOption = solomonRelihanAssistantOptions[0];
    break;
  case "venturesConcierge":
    groupedOptions = venturesConciergeGroupedOptions;
    defaultOption = venturesConciergeAssistantOptions[0];
    break;
  case "WellPsyche":
    groupedOptions = wellPsycheGroupedOptions;
    defaultOption = wellPsycheAssistantOptions[0];
    break;
  default:
  case "MASTER":
    groupedOptions = masterGroupedOptions;
    /* Can change this in the future */
    defaultOption = eldepalAssistantOptions[0];
}

export const assistantGroupedOptions = groupedOptions;
export const defaultAssistantOption = defaultOption;
