import breakthroughLogoWhite from "./breakthrough_logo_white.png";
import breakthroughLogoBlack from "./breakthrough_logo_black.png";
import poweredByTaproot from "./poweredByTaproot.png";
import decisionTreeLogoColor from "./decisiontree_logo_color.png";

export interface ImageLookup {
  [key: string]: string;
}

const images: ImageLookup = {
  asu: breakthroughLogoWhite,
  taproot: poweredByTaproot,
  sisterLove: breakthroughLogoBlack,
  solomonRelihan: decisionTreeLogoColor,
};
export default images;
