import React from "react";
import {
  Box,
  Text,
  Button,
  Container,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import notFoundImages, { ImageLookup } from "assets/not-found";

const BRAND_OPTIONS_KEY = process.env.REACT_APP_BRAND_OPTIONS_KEY;
let notFoundImage = notFoundImages[BRAND_OPTIONS_KEY as keyof ImageLookup];
if (!notFoundImage) {
  notFoundImage = notFoundImages["DTAI"];
}

const NotFoundPage: React.FC = () => {
  return (
    <Container centerContent>
      <Box textAlign="center" py={10} px={6}>
        <Image
          display="inline-block"
          src={notFoundImage}
          alt="404 Graphic"
          mb={4}
        />
        <Text fontSize="18px" mt={3} mb={2} color="teal.500">
          Page Not Found
        </Text>
        <Text color={"gray.500"} mb={6}>
          Shoot, you reached a page that is no longer here.
        </Text>
        <Text color={"gray.500"} mb={6}>
          Sorry about that.
        </Text>
        <ChakraLink
          as={RouterLink}
          to="/"
          style={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
        >
          <Button
            colorScheme="teal"
            bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
            color="white"
            variant="solid"
          >
            Go to Home
          </Button>
        </ChakraLink>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
