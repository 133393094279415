const venturesConciergeAssistantOptions = [
  {
    value: "asst_MYrAd1HXHThVOLgtXkN0Ub3S",
    label: "Sydnie",
    aiName: "Sydnie",
  },
];

export const venturesConciergeGroupedOptions = [
  {
    label: "Venture Madness",
    options: venturesConciergeAssistantOptions,
  },
];

export default venturesConciergeAssistantOptions;
