import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "components/NavBar";
import ResourcesPage from "pages/ResourcesPage";
import VideoPlayerPage from "pages/VideoPlayerPage";
// import MobileVideoPlayer from "components/MobileVideoPlayer";
// import Notifications from './components/Notifications';
// import Options from './components/Options';
// import Settings from "./components/Settings";

import Calendly from "pages/CalendlyPage";
import ShareAndTryMobilePage from "pages/ShareAndTryMobilePage";
import VisionPage from "pages/VisionPage";
import NotFoundPage from "pages/NotFoundPage";
import ComingSoonPage from "pages/ComingSoonPage";
import AIComingSoonPage from "pages/AIComingSoonPage";

/* FEATURE FLAGS */
const FEATURE_FLAG_AI_ENABLED =
  Number(process.env.REACT_APP_FEATURE_FLAG_AI_ENABLED) || null;
const FEATURE_FLAG_RESOURCES_ENABLED =
  Number(process.env.REACT_APP_FEATURE_FLAG_RESOURCES_ENABLED) || null;
const FEATURE_FLAG_SHARE_OR_TRY_MOBILE =
  Number(process.env.REACT_APP_FEATURE_FLAG_SHARE_OR_TRY_MOBILE) || null;

function App() {
  const renderHomePage = () => {
    if (FEATURE_FLAG_AI_ENABLED) {
      return <Route path="/" element={<VideoPlayerPage />} />;
    } else {
      return <Route path="/" element={<AIComingSoonPage />} />;
    }
  };

  return (
    <Router>
      <NavBar>
        <Routes>
          {renderHomePage()}
          {!!FEATURE_FLAG_RESOURCES_ENABLED && (
            <Route path="/resources" element={<ResourcesPage />} />
          )}
          <Route path="/calendly" element={<Calendly />} />
          {!!FEATURE_FLAG_SHARE_OR_TRY_MOBILE && (
            <Route path="/share" element={<ShareAndTryMobilePage />} />
          )}
          <Route path="/vision" element={<VisionPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </NavBar>
    </Router>
  );
}
export default App;
