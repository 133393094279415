import React from "react";
import { Text } from "@chakra-ui/react";

type CopyrightProps = {
  position?: "pre" | "post";
  children?: React.ReactNode;
};

const getCurrentYear = () => new Date().getFullYear();

const Copyright: React.FC<CopyrightProps> = ({
  position = "pre",
  children,
}) => {
  return (
    <Text>
      {position === "pre" && (
        <>
          &#169; Copyright {getCurrentYear()} {children}
        </>
      )}
      {position === "post" && (
        <>
          {children} &#169; Copyright {getCurrentYear()}
        </>
      )}
    </Text>
  );
};

export default Copyright;
