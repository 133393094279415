import { GroupBase } from "chakra-react-select";

import { Script, Scripts, ScriptVariables, ScriptOption } from "./types";

import ozziScripts, {
  ozziScriptOptions,
  ozziGroupedOptions,
} from "./ozziScripts";
import wellPsycheScripts, {
  wellPsycheOptions,
  wellPsycheGroupedOptions,
} from "./wellPsycheScripts";

const masterGroupedOptions: GroupBase<ScriptOption>[] = [
  {
    label: "OZO",
    options: ozziScriptOptions,
  },
  {
    label: "WellPsyche",
    options: wellPsycheOptions,
  },
];

const masterScripts: Scripts = { ...ozziScripts, ...wellPsycheScripts };

let defaultOption: ScriptOption | null;

let groupedOptions: GroupBase<ScriptOption>[];
switch (process.env.REACT_APP_DEMO_SCRIPT_OPTIONS) {
  case "OZO":
    groupedOptions = ozziGroupedOptions;
    defaultOption = ozziScriptOptions[0];
    break;
  case "WellPsyche":
    groupedOptions = wellPsycheGroupedOptions;
    defaultOption = wellPsycheOptions[0];
    break;
  default:
  case "MASTER":
    groupedOptions = masterGroupedOptions;
    /* Can change this in the future */
    defaultOption = wellPsycheOptions[0];
}

export const demoScriptGroupedOptions = groupedOptions;
export const defaultDemoScriptOption = defaultOption;

const demoScripts = {
  /* TODO: replace with assistant API calls instead. Right now we're cheating. */
  getScript: (
    demoScriptKey: keyof Scripts,
    scriptVariables: ScriptVariables,
  ) => {
    const script = masterScripts[demoScriptKey];
    if (!script) {
      throw new Error(`No script found for key ${demoScriptKey}`);
    }
    let scriptPopulatedWithVariables: Script = [];
    for (const segment of script) {
      for (const variableName in scriptVariables) {
        const newValue = scriptVariables[variableName as keyof ScriptVariables];

        if (segment.text.includes(variableName)) {
          segment.text = segment.text.replace(`{${variableName}}`, newValue);
        }
        scriptPopulatedWithVariables.concat([segment]);
      }
    }

    return script;
  },
};

export default demoScripts;
